<template>
  <mention-tracking-form :is-loading="isLoading" @submit="handleSubmit" />
</template>

<script>
// Import child components
const MentionTrackingForm = () => import(/* webpackChunkName: "mention-tracking-form" */ "@/components/crm/mention-tracking/Form.vue")

// Export the SFC
export default {
  // Name of the SFC
  name: "MentionTrackingCreate",

  // Register children components
  components: {
    MentionTrackingForm
  },

  // Define local data variables
  data: () => ({
    // Whether we're making a network request or not
    isLoading: false
  }),

  // Define local method functions
  methods: {
    /**
     * Handle the submit event from child form view
     *
     * @returns {void}
     */
    async handleSubmit(formData) {
      // Otherwise, set a global loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isLoading = true

      // Make the network request
      try {
        // Get the response
        const response = await axios({
          url: "/api/mention-tracking",
          method: "POST",
          data: {
            name: formData.name,
            platforms: Object.keys(formData.platforms).filter((key) => formData.platforms[key]),
            start_date: formData.startDate,
            end_date: formData.endDate,

            hashtags: formData.hashtags.length === 0 ? null : formData.hashtags.map((item) => item.replaceAll("#", "").trim()),
            usernames: formData.usernames.length === 0 ? null : formData.usernames.map((item) => item.replaceAll("@", "").trim()),
            keywords: formData.keywords.length === 0 ? null : formData.keywords.map((item) => item.trim()),

            hashtag_condition: formData.hashtagCondition || null,
            username_condition: formData.usernameCondition || null,
            keyword_condition: formData.keywordCondition || null,
            hashtag_username_condition: formData.hashtagAndUsernameCondition || null,

            only_sponsored_posts: formData.onlySponsoredPosts || false
          }
        })

        // Refresh the user's balance
        fetchProfile()

        // Show a success toast
        this.$store.dispatch("toasts/add", { text: "Report is being generated ..." })

        // Since it was successful, redirect the user to view the campaign
        this.$router.replace({
          name: "MentionTrackingReport",
          params: {
            uuid: response.data.uuid
          }
        })
      } catch (error) {
        // Log the error
        logger({ type: "MentionTracking/Create Error", error })

        // Show an error toast
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })
      } finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isLoading = false
      }
    }
  }
}
</script>
